<template>
  <div class="modal fade modal-fullscreen" id="archiveObjectModal" ref="archiveObjectModal" tabindex="-1"
       aria-labelledby="Перенос в архив"
       data-bs-backdrop="static"
       data-bs-keyboard="false"
       aria-hidden="true">
    <div class="modal-dialog modal-fullscreen modal-fullscreen modal-dialog-scrollable">
      <div class="modal-content p-0 rounded-1">
        <div class="modal-header row p-0 pt-2 px-3">
          <div class="d-flex col-12 align-items-center container-fluid pt-2 px-3 mb-2">
            <span class="modal-title fs-2 fw-semi font-semi me-auto" style="max-width: 90%;">Перенос в архив</span>
            <button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
        </div>
        <div class="modal-body px-4 border-0">
          <div v-if="!this.isEmpty(this.errors)" class="d-flex flex-column mb-10">
						<span class="alert alert-danger mb-2"
                  v-for="(error, index) in this.errors"
                  :key="index"
                  v-text="error"/>
          </div>
          <div class="d-flex flex-column mb-10">
            <InputSelect inputId="reason"
                         inputLabel="Причина переноса в архив:"
                         inputName="reason"
                         ref="reason"
                         v-if="!this.item.is_deal"
                         :inputOptions="this.reasons_list"
                         :inputValue="(!this.isEmpty(this.item.reason)) ? this.item.reason : null"
                         inputClass="rounded-1"
                         inputWrapClass="flex-column mb-3"
                         inputUsePlaceholder="true"
                         @setValue="(value) => this.setAndValidate('reason', value)"/>

            <TextArea inputId="comment"
                      inputLabel="Комментарий:"
                      inputName="comment"
                      ref="comment"
                      inputLabelClass="text-font-secondary mb-3"
                      :inputValue="(!this.isEmpty(this.item.comment)) ? this.item.comment : null"
                      inputClass="rounded-3 p-2"
                      inputWrapClass="mb-20"
                      @setValue="(value) => this.setAndValidate('comment', value)"/>

            <Switcher inputId="is_deal"
                      inputName="is_deal"
                      ref="is_deal"
                      inputLabel="По объекту прошла сделка"
                      :inputValueTo="(!this.isEmpty(this.item.is_deal)) ? this.item.is_deal : null"
                      inputClass="px-0"
                      inputWrapClass="flex-column mb-3"
                      @setValue="(value) => this.setAndValidate('is_deal', value)"/>
            <InputText inputId="price"
                       inputName="price"
                       ref="price"
                       v-if="this.item.is_deal"
                       inputLabel="Цена продажи объекта:"
                       inputLabelClass="text-font-secondary mb-3"
                       inputClass="rounded-3 p-2"
                       inputWrapClass="mb-2"
                       :inputValue="(!this.isEmpty(this.item.price)) ? this.item.price : null"
                       :inputMask="['# ###', '## ###', '### ###', '# ### ###', '## ### ###', '### ### ###', '# ### ### ###']"
                       inputMode="numeric"
                       inputAppend=" руб."
                       inputStep="10000"
                       inputMin="0"
                       inputMax="2147000000"
                       inputIsPrice="true"
                       inputRequired="true"
                       @setValue="(value) => this.setAndValidate('price', value)"/>

          </div>

        </div>
        <div class="modal-footer position-sticky px-4">
          <button
              type="button"
              class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
              @click="this.deBounce(this.sendToArchive(), 1000)">
            Отправить в архив
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import CommonService from "@/services/CommonService";
import InputText from "@/components/inputs/InputText";
import Switcher from "@/components/inputs/Switcher";
import InputSelect from "@/components/inputs/InputSelect";
import TextArea from "@/components/inputs/TextArea";

export default {
  name: "ArchiveObjectModal",
  components: {
    TextArea,
    InputSelect,
    Switcher,
    InputText,
  },
  data() {
    return {
      object_id: (typeof this.$props.objectId !== "undefined") ? this.$props.objectId : null,
      section: '',
      item: {
        reason: '',
        comment: '',
        is_deal: false,
        price: 0,
      },
      is_form_changed: false,
      reasons_list: [],
      errors: [],
    }
  },
  methods: {
    deBounce(callback, timeout) {
      return CommonService.debounce(callback, timeout);
    },
    isEmpty(data) {
      return CommonService.isEmpty(data);
    },
    resetData() {
      this.object_id = null;
      this.item = {
        reason: '',
        comment: '',
        is_deal: false,
        price: 0,
      };
    },
    getReasonsList() {
      return api.get('/common/reasons').then((response) => {
        CommonService.log('debug', 'getReasonsList()::axios', {response: response.data});
        if (response.status == 200 && response.data.success) {
          let list = [];
          Object.entries(response.data.list).forEach(([key, option]) => {
            list.push({
              value: option.id,
              name: option.name
            });
          });

          this.$store.commit('setReasonsList', list);
          return list.map(el => { return { value: el.name, name: el.name } });
        }
      }).catch(function (error) {
        CommonService.log('error', 'getReasonsList()::axios', error);
      });
    },
    setAndValidate(name, value) {
      console.log(name, value)
      let errors = [];
      // Валидация значения
      if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
        if (this.$refs[name].inputLabel) {
          let label = this.$refs[name].inputLabel.replace(/:+$/, '');

          let is_skip = false;

          if (this.$refs[name].required && !is_skip) {
            if (value) {
              if (value.toString() == '0' || value.toString() == '-1')
                errors.push('Поле `' + label + '` обязательно к заполнению!');
              else if (this.isEmpty(value))
                errors.push('Поле `' + label + '` обязательно к заполнению!');
            } else {
              errors.push('Поле `' + label + '` обязательно к заполнению!');
            }
          }
          if (!this.isEmpty(errors)) {
            this.errors[name] = {
              name: name,
              label: label,
              errors: errors
            };
            this.$refs[name].valid = false;
            this.$refs[name].invalid = errors.join('<br/>');
          } else {
            this.errors[name] = null;
            this.$refs[name].valid = true;
            this.$refs[name].invalid = false;
            this.item[name] = value;
          }
        }
      }
      this.is_form_changed = true;
      CommonService.log('debug', 'setAndValidate()', {
        name: name,
        value: value,
        is_form_changed: this.is_form_changed,
        errors: errors
      });
      if (!this.isEmpty(errors))
        return false;
      return true;
    },
    validateAll(data) {
      return true; // @todo: setup validation
    },
    sendToArchive() {
      if (this.validateAll(this.item)) {
        api.post('/objects/single_archived', {...this.item, ...{object_id: this.object_id}}).then((response) => {
          CommonService.log('debug', 'sendToArchive()::axios', {response});
          if (response.status == 200 && response.data.success) {
            this.$emit('objectArchived');
            this.emitter.emit("objectArchived");
            this.$emit('updateObjectsList');
            let modal = CommonService.getModal('archiveObjectModal')
            if (modal)
              modal.hide();
          } else {
            if (!this.isEmpty(response.data.errors)) {
              this.errors = response.data.errors;
            }
          }
        }).catch(function (error) {
          CommonService.log('error', 'sendToArchive()::axios', error);
        });
      }
    }
  },
  mounted() {
    this.is_state_ready = false;
    this.modal = document.getElementById('archiveObjectModal');
    if (this.modal && typeof this.modal !== "undefined") {
      let _this = this;
      this.modal.addEventListener('hidden.bs.modal', function (event) {
        _this.object_id = 0;
        _this.item = {
          autosearch: {
            filter: null
          },
          fields: null
        };
        _this.emitter.emit("global.modalClose", this.modal);
      });
      this.modal.addEventListener('shown.bs.modal', function (event) {
        _this.getReasonsList().then((list) => _this.reasons_list = list);

        let target = event.relatedTarget;

        if (typeof target !== "undefined") {
          if (typeof target.getAttribute('data-bs-object-id') != "undefined") {
            _this.section = 'objects';
            _this.object_id = target.getAttribute('data-bs-object-id');
          }
        }
        if (process.env.NODE_ENV == "development") {
          console.debug('historyTasksModal::show.bs.modal', {
            section: _this.section,
            object_id: _this.object_id,
            client_id: _this.client_id,
            requisition_id: _this.requisition_id,
          });
        }
        _this.emitter.emit("global.modalShown", this.modal);
      });
    }
  },
}
</script>
